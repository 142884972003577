<template>
  <div>
    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-md-6 mb-5">
            <div class="row">
              <div class="col-md-12 mb-5">
                <b-form-group
                  id="input-group-1"
                  label="Cliente:"
                  label-for="input-1"
                  :description="form.full_name"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.dni"
                    type="text"
                    placeholder="Ingrese el DNI"
                    required
                    v-on:keyup.enter="searchPerson"
                  ></b-form-input>
                  <b-form-checkbox
                    v-model="form.is_vale"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Generar un vale
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="form.is_factura"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Deseo una factura
                  </b-form-checkbox>
                  <b-form-input
                    v-if="form.is_factura === true"
                    v-model="form.ruc"
                    type="text"
                    placeholder="Ingrese el RUC"
                    required
                    v-on:keyup.enter="searchPerson"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <h4>Medicamentos seleccionados</h4>
            <b-table :items="selectedProducts" :fields="selectedProductFields">
              <template #cell(price)="data">
                {{ getFixedPrice(data.item.price) }}
              </template>
              <template #cell(total)="data">
                {{ getFixedPrice(data.item.total) }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  variant="danger"
                  @click="removeSelectedProduct(data.item)"
                  >X</b-button
                >
              </template>
            </b-table>
            <div class="row">
              <div class="col-md-6" v-if="buttonSellIsVisible">
                <h3>S/ {{ getFixedPrice(total) }}</h3>
              </div>
              <div class="col-md-6">
                <b-button
                  variant="primary"
                  v-if="buttonSellIsVisible"
                  @click="saveSell"
                  class="pull-right"
                  >Vender</b-button
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12">
              <label class="typo__label">Buscar medicamentos</label>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Ingrese el nombre"
              ></b-form-input>
              <b-table
                :items="products"
                :fields="productFields"
                :filter="filter"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
              >
                <template #cell(quantity_to_sell)="data">
                  <b-input
                    type="number"
                    step="1"
                    value="1"
                    :id="'quantity-' + data.item.id"
                  ></b-input>
                </template>
                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mb-2"
                    variant="success"
                    :id="'check-' + data.item.id"
                    @click="selectProduct(data.item)"
                  >
                    <b-icon icon="check" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "DrugSell",
  data() {
    return {
      form: {
        dni: null,
        full_name: "",
        is_vale: false,
        ruc: null,
        is_factura: false
      },
      isLoading: false,
      person: "00000000",
      persons: [],
      no_client_document_number: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filter: null,
      product: null,
      products: [],
      selectedProducts: [],
      selectedProductFields: [
        {
          key: "name",
          label: "Nombre"
        },
        {
          key: "quantity",
          label: "Cant."
        },
        {
          key: "price",
          label: "Precio"
        },
        {
          key: "total",
          label: "Total"
        },
        {
          key: "actions",
          label: ""
        }
      ],
      productFields: [
        {
          key: "name",
          label: "Nombre"
        },
        {
          key: "price",
          label: "Precio"
        },
        { key: "quantity", label: "Disponible" },
        {
          key: "quantity_to_sell",
          label: "A vender"
        },
        {
          key: "action",
          label: "Acciones"
        }
      ]
    };
  },
  mounted() {
    this.getSerie();
    this.getProducts();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Farmacia" },
      { title: "Venta medicamentos" }
    ]);
  },
  methods: {
    removeSelectedProduct(item) {
      this.selectedProducts = this.customLodash.filter(
        this.selectedProducts,
        function(el) {
          return el.id !== item.id;
        }
      );
    },
    searchPerson() {
      ApiService.get(`person/search/dni`, `?dni=${this.form.dni}`).then(res => {
        this.form.full_name = res.data.full_name;
      });
    },
    getSerie() {
      // Check if I have a uuid in localStorage
      if (localStorage.getItem("pos_key") === null) {
        localStorage.clear();
        this.$router.push({ name: "login" });
      } else {
        ApiService.get(
          `machine-id/identifier`,
          `?identifier=${localStorage.pos_key}`
        )
          .then(() => {
            console.log("Esta máquina sí tiene una serie");
          })
          .catch(() => {
            this.$router.push({ name: "machine_id_create" });
          });
      }
    },
    saveSell() {
      ApiService.post("drug/sell/", {
        pos_key: localStorage.getItem("pos_key"),
        form: this.form,
        products: this.selectedProducts
      }).then(async res => {
        const ruc = process.env.VUE_APP_ENTERPRISE_RUC;
        await this.axios.post(`http://localhost:3030/drugs/${ruc}/`, res.data);
        await this.axios.post(`http://localhost:3030/drugs/${ruc}/`, res.data);
        alert("Venta correcta");
        location.reload();
      });
    },
    getFixedPrice(price) {
      return price.toFixed(2);
    },
    selectProduct(product) {
      let quantity = document.getElementById(`quantity-${product.id}`).value;
      // Check if product exists
      let index = this.customLodash.findIndex(this.selectedProducts, {
        id: product.id
      });
      if (index === -1) {
        let prod = {
          id: product.id,
          quantity: parseInt(quantity),
          price: parseFloat(product.price),
          total: parseFloat(quantity) * parseFloat(product.price),
          name: product.name
        };
        this.selectedProducts.push(prod);
      } else {
        let currentQuantity = this.selectedProducts[index]["quantity"];
        currentQuantity += parseInt(quantity);
        this.selectedProducts[index]["quantity"] = currentQuantity;
        this.selectedProducts[index]["total"] =
          currentQuantity * parseFloat(product.price);
      }
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getProducts() {
      ApiService.get("drug").then(res => {
        this.products = res.data;
        this.totalRows = this.products.length;
      });
    }
  },
  computed: {
    total() {
      return this.customLodash.sumBy(this.selectedProducts, "total");
    },
    buttonSellIsVisible() {
      return this.selectedProducts.length > 0;
    }
  }
};
</script>
